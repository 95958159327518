import { UserRole } from "@roda/shared/types";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import toast from "react-hot-toast";
import { useIntercom } from "react-use-intercom";

import { PlanModal } from "~/components/PlanModal";
import { ReminderBanner } from "~/components/ReminderBanner";
import { useCurrentUser } from "~/contexts/UserContext";
import { useMarkPaymentPrompt } from "~/hooks/company";
import { parseDbDate } from "~/utils/dates/parseDbDate";
import dayjs from "~/utils/dayjs";
import Sentry from "~/utils/sentry";

import type React from "react";
import type { PropsWithChildren } from "react";

interface SubscriptionProps {
  renderPaymentPrompt: () => JSX.Element | null;
  isSubscriptionActive: boolean;
  handlePortal: () => void;
  isSubscriptionBannerActive: boolean;
  loadingPortal: boolean;
  trialDaysRemaining: number | null;
  activeOrTrialing: boolean;
  setPlanModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const SubscriptionContext = createContext<SubscriptionProps>({
  isSubscriptionActive: false,
  loadingPortal: false,
  renderPaymentPrompt: () => null,
  trialDaysRemaining: null,
  isSubscriptionBannerActive: false,
  handlePortal: () => null,
  activeOrTrialing: false,
  setPlanModal: () => null
});

export const SubscriptionProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { user } = useCurrentUser();
  const [ planModal, setPlanModal ] = useState(false);
  const didCancelSubscription = user?.company?.stripeStatus === "canceled";
  const didNotPaySusbcription = user?.company?.stripeStatus === "unpaid" || user?.company?.stripeStatus === "past_due";
  const isSubscriptionActive = !!user?.company?.stripeSubscriptionRef && (user?.company?.stripeStatus === "active" || user?.company?.stripeStatus === "past_due" || user.company.stripeStatus === "trialing");
  // We end the trial at the end of the day on trialExpiresAt date
  const trialDaysRemaining = parseDbDate(user?.company?.trialExpiryAt).endOf("day").diff(dayjs().endOf("day"), "days");
  const [ , markPaymentPromptReq ] = useMarkPaymentPrompt();
  const [ shouldPrompt, setShouldPrompt ] = useState(false);
  const [ loadingCheckout, setLoadingCheckout ] = useState(false);
  const [ loadingPortal, setLoadingPortal ] = useState(false);
  const searchParams = new URLSearchParams(document.location.search);
  const checkoutStatus = searchParams.get("checkout");
  const { showNewMessage } = useIntercom();

  useEffect(() => {
    if (checkoutStatus === "success") {
      // clear search params:
      window.history.replaceState(null, "", window.location.pathname);
      toast.success("Thanks for subscribing!", { id: "checkout" });
    }

    if (checkoutStatus === "error") {
      // clear search params:
      window.history.replaceState(null, "", window.location.pathname);
      showNewMessage("There was a problem with my checkout.");
      toast.error("There was a problem completing checkout. Please contact support.", { id: "checkout" });
    }
  }, [ checkoutStatus, showNewMessage ]);

  const activeOrTrialing = useMemo(() => {
    // Check if onboarding has been completed and if there's an active subscription or an active trial session
    // Always return true for roda admins
    if ((user?.company?.completedOnboarding && (isSubscriptionActive || trialDaysRemaining > -1) || user?.role === UserRole.RODA_ADMIN)) {
      return true;
    } else {
      return false;
    }
  }, [
    isSubscriptionActive,
    trialDaysRemaining,
    user
  ]);

  useEffect(() => {
    if (
      user?.role !== UserRole.RODA_ADMIN && // Don't show to Roda Admins (todo?)
      user?.company?.completedOnboarding && // Only show to onboarded companies
      (
        !activeOrTrialing || // ensure they are active or trialling, or
        (
          user?.role === UserRole.ADMIN && // User is an admin
          user?.company?.paymentPrompt && // Payment prompt is set to show
          !isSubscriptionActive // and no subscription is active
        ) ||
        didNotPaySusbcription // Or they didn't pay!
      )) {
      setShouldPrompt(true);
    } else {
      // This state defaults to false, but allows state changes to set this back (ie changing the date in development)
      setShouldPrompt(false);
    }
  }, [
    user,
    didNotPaySusbcription,
    isSubscriptionActive,
    activeOrTrialing
  ]);

  const dismissPaymentPrompt = useCallback(() => {
    if (user?.companyId) {
      // Immediately dismiss the prompt
      setShouldPrompt(false);

      markPaymentPromptReq({
        companyId: user?.companyId,
        shouldPrompt: false
      });
    }
  }, [ markPaymentPromptReq, user ]);

  const handleCheckout = async () => {
    try {
      setLoadingCheckout(true);

      const response = await fetch(import.meta.env.VITE_BASE_URL + "/subscription/checkout", {
        method: "POST",
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
      });

      const body = await response.json();

      setLoadingCheckout(false);

      if (response.status === 200 && body.message) {
        toast(body.message);
      }

      if (body.redirect) {
        window.location.href = body.redirect;
      }
    } catch (error) {
      setLoadingCheckout(false);
      toast.error("There was a problem checking out.");
      Sentry.captureMessage("There was a problem checking out.");
    }
  };

  const handlePortal = async () => {
    try {
      setLoadingPortal(true);

      const response = await fetch(import.meta.env.VITE_BASE_URL + "/subscription/portal", {
        method: "POST",
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
      });

      const body = await response.json();

      if (response.status === 200 && body.message) {
        toast(body.message);
      }

      if (body.redirect) {
        window.location.href = body.redirect;
      }

      setLoadingPortal(false);
    } catch (error) {
      setLoadingPortal(false);
      Sentry.captureMessage("There was a problem launching the Subscription portal.");
      toast.error("There was a problem launching your Subscription portal.");
    }
  };

  const renderPaymentPrompt = () => {
    // Setup defaults
    let bannerTitle = "",
      bannerText = "Add payment details when you are ready",
      actionText = "See pricing",
      onAction: (() => void) | undefined = () => setPlanModal(true),
      loading = false,
      onDismiss: (() => void) | undefined = undefined;

    // When the company is mid-trial
    if (trialDaysRemaining > -1) {
      bannerTitle = `Your trial ends ${trialDaysRemaining === 1 ? "tomorrow" : trialDaysRemaining > 0 ? `in ${trialDaysRemaining} days` : "today"}`;
      onDismiss = dismissPaymentPrompt;
    } else {
      // When the company trial has ended
      bannerTitle = "Your 30 day free trial has ended";
      bannerText = "Please add payment information";
    }

    if (didCancelSubscription) {
      bannerTitle = "Your subscription ended.";
      bannerText = "Please renew to continue using Roda" ;
      actionText = "See pricing";
      onDismiss = undefined;
    }

    if (didNotPaySusbcription) {
      bannerTitle = "There's a problem with your payment method.";
      bannerText = "Please resolve to continue using Roda";
      onDismiss = undefined;

      actionText = "Manage subscription",

      loading = loadingPortal;
      onAction = () => handlePortal();
    }

    // Normal users should see the banner, but only admins can act
    if (user?.role === UserRole.USER) {
      onAction = undefined;
      bannerText = "Enjoying Roda? Please ask your admin to subscribe.";
    }

    if (!shouldPrompt) {
      return null;
    }

    return (
      <div className="w-full items-center justify-center flex h-auto">
        <ReminderBanner
          bannerTitle={bannerTitle}
          bannerText={bannerText}
          actionText={actionText}
          loading={loading}
          onAction={onAction}
          dismissText={"I'll do it later"}
          onDismiss={onDismiss}
        />
      </div>
    );
  };

  return (
    <SubscriptionContext.Provider
      value={{
        renderPaymentPrompt,
        activeOrTrialing,
        isSubscriptionBannerActive: shouldPrompt,
        isSubscriptionActive,
        trialDaysRemaining,
        loadingPortal,
        setPlanModal,
        handlePortal
      }}
    >

      {planModal && (
        <PlanModal
          trialHasEnded={!activeOrTrialing}
          handleCheckout={handleCheckout}
          loading={loadingCheckout}
          close={() => setPlanModal(false)}
        />
      )}

      {children}
    </SubscriptionContext.Provider>
  );
};

export const useRodaSubscription = () => useContext(SubscriptionContext);
