import { enumUserRole } from "@roda/graphql/genql";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import SetupCompleteImg from "~/assets/illustrations/setup.png";
import { Button } from "~/components/Button";
import { routes } from "~/constants/routes";
import { useSelectedFlywheel } from "~/contexts/SelectedFlywheelContext";
import { useCurrentUser } from "~/contexts/UserContext";
import { useMarkCompanyAsOnboarded } from "~/hooks/company";
import { useError } from "~/hooks/useError";
import { useIsMobile } from "~/hooks/useIsMobile";

export const ReadyToStart: React.FC = () => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [ markCompanyAsOnboardedRes, markCompanyAsOnboardedReq ] = useMarkCompanyAsOnboarded();
  const { user } = useCurrentUser();
  const { handleRodaError } = useError();
  const { flywheel, loading: fetchingFlywheels } = useSelectedFlywheel();
  const isRodaAdmin = user?.role === enumUserRole.RODA_ADMIN;

  useEffect(() => {
    if (markCompanyAsOnboardedRes.data) {
      // Navigate user to "Dashboard" page
      navigate(routes.dashboard(), { replace: true });
    }

    if (markCompanyAsOnboardedRes.error) {
      handleRodaError(markCompanyAsOnboardedRes.error, "Something went wrong - please try again");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    markCompanyAsOnboardedRes.data,
    markCompanyAsOnboardedRes.error,
    handleRodaError,
    navigate
  ]);

  // Navigate back to dashboard if
  // is a roda admin
  // onboarding has been completed
  // onboarding has not been completed and there's not a flywheel with setupComplete as true
  useEffect(() => {
    if (isRodaAdmin || user?.company?.completedOnboarding || (!user?.company?.completedOnboarding && !fetchingFlywheels && !flywheel?.setupComplete)) {
      navigate(routes.dashboard(), { replace: true });
    }
  }, [
    fetchingFlywheels,
    flywheel?.setupComplete,
    isRodaAdmin,
    navigate,
    user?.company?.completedOnboarding
  ]);

  if (!user) {
    return null;
  }

  return (
    <div
      className={`flex w-full ${isMobile ? "flex-col-reverse" : "flex-row"}`}
      style={{ height: "100dvh" }}
    >
      <div className={`flex flex-row h-full flex-1 flex-center ${isMobile ? "w-full " : "w-1/2"} `}>

        <div className="w-full flex flex-col gap-4 px-8 py-12 max-w-[500px] flex-1 self-start desktop:self-center">

          <p className={`font-bold ${isMobile ? "text-2xl" : "text-3xl"}`}>Setup complete!</p>

          <p className="text-brand-cold-metal-500 text-[15px] desktop:text-base">
            Your flywheel is all setup and ready to go.
          </p>

          <p className="text-brand-cold-metal-500 text-[15px] desktop:text-base">
            Your 30 day free trial has started - use this time to evaluate Roda and see if it works for you. When you are ready, you can add payment details to continue your plan.
          </p>

          <Button
            title="Start 30 day free trial"
            loading={markCompanyAsOnboardedRes.fetching}
            onClick={() => {
              if (user.companyId)
                markCompanyAsOnboardedReq({ companyId: user.companyId });
            }}
            className="px-6 mt-4 text-base sm:text-lg font-medium"
          />

        </div>
      </div>

      {/* Image Container RIGHT SIDE OF SCREEN 50/50 SPLIT */}

      <div className={`relative h-[100dvh] w-1/2 bg-brand-cold-metal-50 ${isMobile ? "w-full h-1/2" : "w-1/2"}`}>
        <img
          src={SetupCompleteImg}
          className="w-full h-full inset-y-0 p-[5vmin] desktop:p-0 absolute object-contain desktop:max-w-[min(35vmax,800px)] desktop:absolute-center"
        />

      </div>

    </div>
  );
};
