import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useIntercom } from "react-use-intercom";

import { Button } from "~/components/Button";
import { Icon } from "~/components/Icon";
import { Modal } from "~/components/Modal";
import { routes } from "~/constants/routes";
import { useRodaAdminCompaniesContext } from "~/contexts/RodaAdminCompaniesContext";
import { useCurrentUser } from "~/contexts/UserContext";
import {
  calculateTotalCost,
  rodaPriceTiers
} from "~/utils/calculatePricing";

interface PlanModalProps {
  close: () => void;
  handleCheckout: () => void;
  loading: boolean
  trialHasEnded: boolean
}

export const PlanModal: React.FC<PlanModalProps> = ({
  close, handleCheckout, loading, trialHasEnded
}) => {
  const { user } = useCurrentUser();
  const navigate = useNavigate();
  const { showNewMessage } = useIntercom();
  const chargedUsers = user?.company?.activeUsers?.filter(user => !!user.verified && user.status === "active")?.length || 1;
  const { currentCompany } = useRodaAdminCompaniesContext();

  const totalCost = useMemo(() => {
    try {
      return calculateTotalCost(chargedUsers);
    } catch (error) {
      console.error(error);
      // Report to Sentry!
    }
  }, [ chargedUsers ]);

  return (
    <Modal onCancel={close}>
      <div
        className="bg-white max-h-[90vh] overflow-auto animate-in gap-16 fade-in p-10 rounded-lg shadow-lg text-left z-20 flex flex-col md:flex-row max-w-[90vw] md:overflow-hidden"
      >
        <div className="md:w-2/5 flex flex-col justify-between gap-10">

          <div>

            <div className="font-bold text-left pb-6 text-2xl">Flexible pricing</div>

            {trialHasEnded ? (

              <p className="text-brand-cold-metal-500 text-sm max-w-[250px]">Here are the details for your plan. Please add payment details now to continue using Roda.</p>
            ) : (
              <p className="text-brand-cold-metal-500 text-sm max-w-[250px]">Here are the details for your plan once your 30 day free trial ends. You can add payment details now if you like - you won't be charged until your plan starts.</p>

            )}

          </div>

          <div>

            <p className="text-brand-cold-metal-800 text-base">Enterprise</p>

            <div className="gap-1 flex flex-row">

              <p className="text-brand-cold-metal-400 text-sm">Looking for more?</p>

              <span
                onClick={() => showNewMessage("I'd like to ask about Roda for enterprise")} // opens intercom
                className="cursor-pointer text-sm text-brand-cold-metal-600 underline"
              >Get in touch.
              </span>
            </div>
          </div>
        </div>

        <div className="md:w-3/5 flex flex-col gap-8">

          <div className="text-left gap-2 text-brand-cold-metal-800 flex flex-row items-center">
            <Icon.InfoCircle />

            <p className="text-sm">

              {chargedUsers > 1 ? `You have ${chargedUsers} active users` : "You're the only active team member"}

              {": "}

              <span
                className="underline cursor-pointer"
                onClick={() => {
                  close();
                  navigate(routes.members(currentCompany ? currentCompany.id : undefined));
                }}
              > {chargedUsers > 1 ? "manage" : "invite others?"}
              </span>
            </p>

          </div>

          <div className="flex flex-col gap-3">
            {rodaPriceTiers.map(tier => {
              const userCount = chargedUsers;
              const isActive = tier.name === "Base plan" || userCount >= tier.min;
              const prog = tier.name === "Base plan" ? 100 : (userCount - tier.batch) / (tier.max - tier.batch) * 100;

              return (
                <div
                  key={tier.name}
                  className="flex flex-row gap-2 items-center"
                >
                  <div className="rounded-lg overflow-hidden relative h-12 w-3 bg-brand-check-in-due-50">
                    {isActive && (
                      <div
                        className="rounded-lg absolute w-3 bg-brand-check-in-due-400"
                        style={{ height: `${prog}%` }}
                      />
                    )}
                  </div>

                  <div className="flex flex-col">
                    <div className="flex flex-row gap-1 items-baseline">
                      <p className={` text-left  text-sm ${isActive ? " text-brand-check-in-due-300" : "text-brand-cold-metal-400"}`}>
                        {tier.name}
                      </p>

                      {tier.sub && (
                        <p className={` text-left text-sm ${isActive ? " text-brand-check-in-due-300" : "text-brand-cold-metal-400"}`}>
                          - {tier.sub}
                        </p>
                      )}
                    </div>

                    <p className={` text-left  text-sm ${isActive ? " text-brand-check-in-due-800" : "text-brand-cold-metal-500"}`}>
                      {tier.pricePerUser ? `£${tier.pricePerUser} per user / month` : `£${tier.basePrice} per month`}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="flex flex-col md:items-end gap-x-2 md:text-right">
            <div className="flex flex-row gap-1 items-center text-brand-cold-metal-400">
              <Icon.InfoCircle className="hidden md:block" />

              <p className="text-sm">After your trial, your monthly bill will be:</p>
            </div>

            <div className="flex flex-row gap-x-2">

              <p className="text-base">£{totalCost}<span className="text-sm">.00 / ex. VAT</span></p>
            </div>
          </div>

          <div className="flex flex-col gap-2 md:flex-row justify-end">
            <Button
              title="Skip for now"
              className="bg-brand-cold-metal-100 flex-1 text-brand-cold-metal-700"
              onClick={close}
            />

            <Button
              title="Add payment details"
              onClick={handleCheckout}
              disabled={!totalCost || loading}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};